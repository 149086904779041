import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  checkBoolean,
  formatNumberWithCap,
  formatPrice,
  handleKeyDown,
} from "../../helpers/helpers";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import Loader from "../../shared/components/Loader/Loader";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { StyledBubble } from "./BoxMiniCart.styled";
import {
  IBoxMiniCartMessages,
  IBoxMiniCartSettings,
} from "./BoxMiniCart.types";
import { IBoxMiniCart } from "./useBoxMiniCart";

interface IMiniCartSimpleProps {
  handleMouseClick: () => void;
  settings: IBoxMiniCartSettings;
  messages: IBoxMiniCartMessages;
  miniCartCount: IBoxMiniCart["miniCartCount"];
  miniCartContent: IBoxMiniCart["miniCartContent"];
}

export const MiniCartDetailed = ({
  settings,
  handleMouseClick,
  messages,
  miniCartCount,
  miniCartContent,
}: IMiniCartSimpleProps) => {
  const {
    advanceSettings: {
      messages: { currencyShort },
    },
  } = useTheme() as IThemeState;
  const bubbleRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobilePortal();

  const { mini_cart_with_value_savings_label } = messages;
  const { count, isMiniCartCountLoading } = miniCartCount;

  const {
    mini_cart_with_value_savings_activity,
    mini_cart_with_value_savings_label_color,
    mini_cart_with_value_savings_label_typo,
    mini_cart_with_value_savings_color,
    mini_cart_with_value_savings_typo,
    mini_cart_with_value_bubble_count_typo,
    mini_cart_with_value_bubble_count_color,
    mini_cart_with_value_value_color,
    mini_cart_with_value_value_typo,
  } = settings;

  useLayoutEffect(() => {
    if (bubbleRef.current) {
      const bubbleHeight = bubbleRef.current.offsetHeight;
      bubbleRef.current.style.minWidth = `${bubbleHeight}px`;
    }
  }, [
    bubbleRef,
    mini_cart_with_value_bubble_count_typo,
    count,
    bubbleRef.current?.offsetHeight,
  ]);

  const bubbleData = bubbleRef.current?.getBoundingClientRect();
  const containerData = containerRef.current?.getBoundingClientRect();

  const [containerHeight, setContainerHeight] = useState<number>(0);

  useEffect(() => {
    if (bubbleData === undefined || containerData === undefined) return;

    const offsetDifference = containerData.y - bubbleData?.y;
    const height =
      offsetDifference > 0
        ? containerData.height + offsetDifference
        : containerData.height;
    if (containerHeight === 0 && containerData !== undefined)
      setContainerHeight(height);
  }, [bubbleData, containerData]);

  const { cartValueAfterDiscounts, cartValueFromProducts } =
    miniCartContent?.content?.cartSummary ?? {};

  const { isMiniCartLoading } = miniCartContent;

  const savedAmount =
    cartValueFromProducts && cartValueAfterDiscounts
      ? cartValueFromProducts - cartValueAfterDiscounts
      : null;
  return (
    <div
      className="mini-cart-detailed"
      id="mini-cart-detailed"
      data-testid="mini-cart-detailed"
    >
      {isMiniCartLoading || isMiniCartCountLoading ? (
        <Loader size={24} borderColor="lightgray" borderSize={2} />
      ) : (
        <>
          <div
            ref={containerRef}
            className="mini-cart-detailed__container"
            data-testid="button-open-minicart"
            role="button"
            tabIndex={0}
            onClick={handleMouseClick}
            onKeyDown={(e) => handleKeyDown(e, handleMouseClick)}
            style={{ height: containerHeight, alignItems: "flex-end" }}
          >
            <StyledButton
              $settings={settings.mini_cart_icon_button}
              renderAs={"button"}
              data-testid="dropdown-button"
              className="mini-cart-detailed__container__cart-icon"
              show={isNotEmptyMessage(messages.mini_cart_icon_open_label)}
            >
              {count > 0 && (
                <StyledBubble settings={settings} ref={bubbleRef}>
                  <StyledText
                    $settings={{
                      font: mini_cart_with_value_bubble_count_typo,
                      text: {
                        color: mini_cart_with_value_bubble_count_color,
                      },
                    }}
                  >
                    {formatNumberWithCap(count)}
                  </StyledText>
                </StyledBubble>
              )}
            </StyledButton>
            {typeof cartValueAfterDiscounts === "number" && !isMobile && (
              <StyledText
                className="mini-cart-detailed__container__value"
                $settings={{
                  font: mini_cart_with_value_value_typo,
                  text: {
                    color: mini_cart_with_value_value_color,
                  },
                }}
              >
                {formatPrice(cartValueAfterDiscounts, currencyShort)}
              </StyledText>
            )}
          </div>

          {checkBoolean(mini_cart_with_value_savings_activity) &&
            !isMobile &&
            typeof savedAmount === "number" &&
            savedAmount > 0 && (
              <span className="mini-cart-detailed__you-save-description">
                <StyledText
                  $settings={{
                    font: mini_cart_with_value_savings_label_typo,
                    text: {
                      color: mini_cart_with_value_savings_label_color,
                    },
                  }}
                >
                  {mini_cart_with_value_savings_label}
                </StyledText>
                <StyledText
                  className="mini-cart-detailed__you-save-description__value"
                  $settings={{
                    font: mini_cart_with_value_savings_typo,
                    text: {
                      color: mini_cart_with_value_savings_color,
                    },
                  }}
                >
                  {formatPrice(savedAmount, currencyShort)}
                </StyledText>
              </span>
            )}
        </>
      )}
    </div>
  );
};
