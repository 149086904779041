import styled, { css } from "styled-components";
import { alignmentOptionsToValue } from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { IBoxMiniCartSettings } from "./BoxMiniCart.types";

export const StyledBoxMiniCartWrapper = styled.div<{
  settings: IBoxMiniCartSettings;
}>`
  display: grid;
  place-content: ${({ settings }) =>
    alignmentOptionsToValue(settings.mini_cart_button_position)};

  .mini-cart-container {
    &__button-open-container {
      position: relative;
      display: flex;
      gap: 10px;
      z-index: var(--zindex-button-open-minicart-container);
      align-items: center;
      cursor: pointer;

      &__text-content {
        display: flex;
        gap: 3px;
      }

      &__text-content__products-count {
        display: flex;
        align-items: center;
      }
    }
  }
  .mini-cart-detailed {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    white-space: nowrap;
    &__container {
      display: flex;
      align-items: center;
      column-gap: 6px;
      &__cart-icon {
        position: relative;
      }
      &__value {
        flex-shrink: 0;
      }
    }
    &__you-save-description {
      display: flex;
      column-gap: 4px;
      justify-content: flex-end;
      align-items: baseline;
      &__value {
        flex-shrink: 0;
      }
    }
  }
`;

export const StyledDropdownWrapper = styled.div<{
  margin: string;
  gap: string;
}>`
  display: flex;
  flex-direction: column;
  box-shadow: var(--ecp-shadow-card);
  padding: ${({ margin }) => margin};
  gap: ${({ gap }) => gap};
  background-color: #ffffff;
  max-height: 80vh;
  overflow: auto;

  .mini-cart-container__dropdown__container {
    display: flex;
    gap: 10px;
    vertical-align: middle;

    &__button-container {
      display: flex;
      justify-content: center;
    }
  }
`;

export const StyledBubble = styled.div<{ settings: IBoxMiniCartSettings }>(
  ({ theme: { colorPalette }, settings }) => {
    const {
      mini_cart_with_value_bubble_color,
      mini_cart_with_value_bubble_count_color,
      mini_cart_with_value_bubble_x_offset,
      mini_cart_with_value_bubble_y_offset,
    } = settings;
    return css`
      position: absolute;
      top: 0;
      right: 0;
      display: grid;
      place-items: center;
      padding: 3px;
      background-color: ${convertColorIdToHex(
        mini_cart_with_value_bubble_color,
        colorPalette
      )};
      color: ${convertColorIdToHex(
        mini_cart_with_value_bubble_count_color,
        colorPalette
      )};
      border-radius: 999px;
      height: auto;
      min-height: 24px;
      max-width: none;
      transform: translate(
        calc(50% + ${mini_cart_with_value_bubble_x_offset}),
        calc(-50% + ${mini_cart_with_value_bubble_y_offset})
      );
      & > div {
        line-height: 1;
      }
    `;
  }
);
